.masonry__item_photo {
  background: url('../img/backgrounds/about/ksusha-2.jpg') no-repeat center center;
  background-size: cover;
  flex-basis: 50%;
  min-height: rem(600px);
}
.masonry__item_bio {
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  flex-basis: 50%;
  padding: rem(20px);
  background-color: #232323;
  z-index: 2;
}

.bio {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: rem(20px) rem(20px);
  &__item_name {
     font-size: rem(30px);
     letter-spacing: 1px;
  }
  &__item_birthday {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: rem(16px);
    margin-top: 40px;
    &:before {
      top: -15px;
    }
  }
  &__item_family {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 15px;
    &:before {
      bottom: -15px;
    }
  }
  &__item_birthday:before,
  &__item_family:before {
    content: '';
    position: absolute;
    background: #fff;
    width: 300px;
    height: 2px;

  }
  &:before,
  &:after {

  }
  &:before {
    top: 2px;
    left: 25%;
  }
  &:after {

  }
}


.preferences {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: rem(10px);
  width: 100%;
  font-size: rem(10px);
  @include tablet {
    flex-wrap: wrap;
  }
  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #2e2835;
    margin: rem(10px);
    padding: rem(20px);
    text-align: center;
    flex-basis: 30%;
    border-radius: 3px;
    text-transform: uppercase;
    @include tablet {flex-basis: 50%}
    @include phone {flex-basis: 100%}

  }
  &__title {
    display: flex;
    align-items: center;
    height: 30px;
    margin-left: rem(5px);

  }

  &__item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    svg {
      width: rem(20px);
      height: rem(20px);
    }
    &:first-child {
      margin-bottom: rem(10px);
    }
  }
}