.modal,
.modal-box {
  z-index: 900;
}

.modal-sandbox {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
}

.modal {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  //background: rgb(0,0,0);
  background-color: rgba(36, 36, 36, .97);
  overflow: auto;
}

.modal-box {
  position: relative;
  width: 50%;
  max-width: 600px;
  margin: 100px auto;
  animation-name: modalbox;
  animation-duration: .3s;
  animation-timing-function: ease-out;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, .3);
  @include tablet {
    width: 80%;
  }
}

.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #222222;

  padding: 60px 40px;
}

/* Close Button */
.close-modal {
  position: absolute;
  right: 15px;
  top: 15px;
  text-align: right;
  cursor: pointer;
  color: #967dac;
}

/* Animation */
@-webkit-keyframes modalbox {
  0% {
    top: -250px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes modalbox {
  0% {
    top: -250px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

.form_modal {
  box-shadow: none;
  background-color:  #ECEFF1;;
}
.close-modal.form_modal__close {
  text-align: center;
}

@media screen and (max-width: 600px) {
  .modal-body,
  .modal-header {
    padding: 20px;
  }
}