.instagram {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: #222222;
  overflow: hidden;
  margin-top: 5px;
  margin-bottom: 10px;
  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    height: 60px;
    width: 100%;

  }
  &__link {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 16px;

    svg {
      margin: 0 5px;
      height: 16px;
      width: 20px;
    }
  }
  &__nickname {
    margin-right: 5px;
    text-decoration: #967dac underline;

  }
  .owl-item {
    background: #343434 url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwcHgiICBoZWlnaHQ9IjIwMHB4IiAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQiIGNsYXNzPSJsZHMtZG91YmxlLXJpbmciIHN0eWxlPSJiYWNrZ3JvdW5kOiBub25lOyI+PGNpcmNsZSBjeD0iNTAiIGN5PSI1MCIgbmctYXR0ci1yPSJ7e2NvbmZpZy5yYWRpdXN9fSIgbmctYXR0ci1zdHJva2Utd2lkdGg9Int7Y29uZmlnLndpZHRofX0iIG5nLWF0dHItc3Ryb2tlPSJ7e2NvbmZpZy5jMX19IiBuZy1hdHRyLXN0cm9rZS1kYXNoYXJyYXk9Int7Y29uZmlnLmRhc2hhcnJheX19IiBmaWxsPSJub25lIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHI9IjQwIiBzdHJva2Utd2lkdGg9IjQiIHN0cm9rZT0iI2ZmZmZmZiIgc3Ryb2tlLWRhc2hhcnJheT0iNjIuODMxODUzMDcxNzk1ODYgNjIuODMxODUzMDcxNzk1ODYiIHRyYW5zZm9ybT0icm90YXRlKDE5Ny44NzcgNTAgNTApIj48YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InJvdGF0ZSIgY2FsY01vZGU9ImxpbmVhciIgdmFsdWVzPSIwIDUwIDUwOzM2MCA1MCA1MCIga2V5VGltZXM9IjA7MSIgZHVyPSIxcyIgYmVnaW49IjBzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSI+PC9hbmltYXRlVHJhbnNmb3JtPjwvY2lyY2xlPjxjaXJjbGUgY3g9IjUwIiBjeT0iNTAiIG5nLWF0dHItcj0ie3tjb25maWcucmFkaXVzMn19IiBuZy1hdHRyLXN0cm9rZS13aWR0aD0ie3tjb25maWcud2lkdGh9fSIgbmctYXR0ci1zdHJva2U9Int7Y29uZmlnLmMyfX0iIG5nLWF0dHItc3Ryb2tlLWRhc2hhcnJheT0ie3tjb25maWcuZGFzaGFycmF5Mn19IiBuZy1hdHRyLXN0cm9rZS1kYXNob2Zmc2V0PSJ7e2NvbmZpZy5kYXNob2Zmc2V0Mn19IiBmaWxsPSJub25lIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHI9IjM1IiBzdHJva2Utd2lkdGg9IjQiIHN0cm9rZT0iIzk2NzVlM2IzIiBzdHJva2UtZGFzaGFycmF5PSI1NC45Nzc4NzE0Mzc4MjEzOCA1NC45Nzc4NzE0Mzc4MjEzOCIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjU0Ljk3Nzg3MTQzNzgyMTM4IiB0cmFuc2Zvcm09InJvdGF0ZSgtMTk3Ljg3NyA1MCA1MCkiPjxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiBjYWxjTW9kZT0ibGluZWFyIiB2YWx1ZXM9IjAgNTAgNTA7LTM2MCA1MCA1MCIga2V5VGltZXM9IjA7MSIgZHVyPSIxcyIgYmVnaW49IjBzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSI+PC9hbmltYXRlVHJhbnNmb3JtPjwvY2lyY2xlPjwvc3ZnPg==) no-repeat center center;
    background-size: 200px;
    height: 270px;
    overflow: hidden;
    position: relative;
    box-shadow: inset 0 0 0 1px rgba(#000, .4);
    &:hover .image-hover {
      opacity: 1;
    }
  }

  .owl-prev, .owl-next {
    height: 57px;
    top: 60%;
    width: 30px;
    z-index: 1000;
    cursor: pointer;
    color: transparent;
    margin-top: -27px;
    outline: none;
    border: none;
  }
  .owl-next {
    right: 10px
  }
  .owl-prev {
    left: 10px;
  }
}

.image-hover {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  color: #fff;
  background-color: rgba(#000, .6);
  transition: opacity .45s;
  pointer-events: none;
  &__likes,
  &__comments {
    position: relative;
    font-size: rem(20px);
    justify-content: center;
    margin: rem(20px);
    width: rem(30px);
    opacity: .8;
    &:before {
      content: '';
      position: absolute;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      //height: rem(20px);
      background-color: #fff;
      bottom: 100%;
    }
  }
  &__likes {
    &:before {
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlPmJhY2tncm91bmQ8L3RpdGxlPjxwYXRoIGQ9Ik0tMS0xaDU4MnY0MDJoLTU4MnoiIGZpbGw9Im5vbmUiLz48dGl0bGU+TGF5ZXIgMTwvdGl0bGU+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE2LjQgNGMtMS44IDAtMy40LjktNC40IDIuMy0xLTEuNC0yLjYtMi4zLTQuNC0yLjMtMy4xIDAtNS42IDIuNS01LjYgNS42IDAgNC40IDEwIDEyLjQgMTAgMTIuNHMxMC04IDEwLTEyLjRjMC0zLjEtMi41LTUuNi01LjYtNS42eiIvPjwvc3ZnPg==) no-repeat center center;
      background-size: 90%;
    }
  }
  &__comments {
    &:before {
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlPmJhY2tncm91bmQ8L3RpdGxlPjxwYXRoIGQ9Ik0tMS0xaDU4MnY0MDJoLTU4MnoiIGZpbGw9Im5vbmUiLz48dGl0bGU+TGF5ZXIgMTwvdGl0bGU+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTAuNzE4IDE4LjU2MWw2Ljc4IDUuMzExYy4xMTEuMDg1LjE3OS4xMjguMjQ1LjEyOC4xODggMCAuMjQ0LS4xMjcuMjQ0LS4zMzh2LTUuMDIzYzAtLjM1NS4yMzMtLjYzNy41NDgtLjYzN2wyLjQ2NS0uMDAyYzIuMjE5IDAgMy0xLjA5NCAzLTJ2LTE0cy0uNzQ4LTItMy4wMTQtMmgtMTcuOTk3Yy0yLjE4NyAwLTIuOTg5Ljk2OS0yLjk4OSAydjE0cy44MjggMiAzIDJoNnMxLjI1NS4wMzUgMS43MTguNTYxeiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PC9zdmc+) no-repeat bottom center;
      background-size: 80%;
    }
  }
}
