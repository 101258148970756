.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: rem(15px);
  width: 100%;
  text-transform: uppercase;
  transition: all 0.5s ease;
  font-family: 'Proxima Nova Regular', sans-serif;
  z-index: 4;
}

.logo {
  display: flex;
  padding: rem(13px);
  height: rem(25px);
  width: rem(25px);
  box-sizing: content-box;
  background-color: rgba(#fff, .8);
  border-radius: 50px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, .2);
  &:hover svg {
    transform: rotate(360deg);
  }
}

.nav {
  display: flex;
  font-size: rem(14px);
  transition: top .9s;

  @include tablet {
    position: absolute;
    top: -9999px;
    left: 0;
    right: 0;
    height: 100%;
    background-color: rgba(36, 36, 36, .97);
    z-index: 10;

    .menu {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    &.open {
      top: 0;
    }
  }


}

.nav_footer {
  font-size: 10px;
  margin-left: -200px;
  text-transform: uppercase;
  .menu__item {
    position: relative;
    margin: rem(12px);
  }
  .menu__item:not(:last-child):after {
    content: '';
    position: absolute;
    right: -14px;
    width: 2px;
    height: 100%;
    background-color: #a1a1a1;
  }
  .menu__link.active {
    color: #967dac;
  }
  .menu__link:after {
    display: none;
  }
  @include tablet {
    display: none;
  }
}

.menu {
  display: flex;
  align-items: center;

  &__item {
    margin: rem(10px) rem(20px);
    @include tablet {
      margin: rem(10px) 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__link {
    position: relative;
    white-space: nowrap;
    color: #fff;
    &.active:after {
      color: #967dac;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      display: inline-block;
      height: 1em;
      width: 100%;
      border-bottom: 2px solid;
      margin-top: 7px;
      color: #967dac;
      opacity: 0;
      transition: opacity 0.35s, transform 0.35s;
      transform: scale(0, 1);
    }

    &:hover:after,
    &.active:after {
      opacity: 1;
      transform: scale(1);
    }
  }
}




.hamburger {
  display: none;
  position: relative;
  color: #000;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.2),
  0 8px 13px rgba(0, 0, 0, 0.2),
  0 18px 23px rgba(0, 0, 0, 0.2);
  z-index: 100;
  @include tablet {
    display: flex;
    align-items: center;
    justify-content: center;

  }
  &__button {
    display: flex;
    width: rem(35px);
    height: 20px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
      display: flex;
      position: absolute;
      height: 4px;
      width: 100%;
      background: #fff;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      &:nth-child(1) {
        top: 0px;
      }
      &:nth-child(2),
      &:nth-child(3) {
        top: 8px;
      }
      &:nth-child(4) {
        top: 16px;
      }
    }

    &.open span {
      &:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
        z-index: 100;
      }
      &:nth-child(2) {
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    }
  }
}



