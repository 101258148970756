.masonry__item_movie {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #222;
  &:not(:last-child) {margin-bottom: 5px}
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: block;
  }
  @include tablet {
    padding: rem(10px);
  }

}

.movie-desc {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  flex: 0 0 auto;
  color: #FFF;
  padding: rem(50px);
  background-color: rgba(#000, .7);
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: block;
    margin: 0 auto;
  }
  @include desktop {
    width: 100%;;
    padding: rem(20px);
  }
  &__title{
    display: flex;
    align-self: flex-start;
    text-align: left;
    font-size: rem(24px);
    text-transform: uppercase;
    text-shadow: 1px 1px 6px rgba(0, 0, 0, .3);
    z-index: 2;


  }
  &__text {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    flex: 0 0 auto;
    flex-direction: column;
    @include phone {
      text-align: justify;
    }
  }
  &__title,
  &__director,
  &__screenplay,
  &__actors,
  &__genre {
    color: #8063c0;
  }
  p {
    margin: rem(10px) 0;
  };
}

.movie-trailer {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: rem(30px);
  @include desktop {
  }
  video {
    max-width: 700px;
    object-fit: cover;
    border: 5px solid #000;
  }
  .plyr__control.plyr__tab-focus, .plyr__control[aria-expanded=true],
  .plyr__control:hover,
  .plyr__control--overlaid {
    background: #8063c0;
  }
  .plyr--full-ui input[type=range] {
    color: #8063c0;
  }
}

