/**
 * Owl Carousel v2.3.2
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
.owl-stage {
  display: flex;
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;

  /* fix firefox animation glitch */ }
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }
.owl-stage-outer {

  overflow: hidden;
  position: relative;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px); }
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%; }
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none; }
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit; }
.owl-carousel.owl-loaded {
  display: block; }
.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }
.owl-carousel.owl-hidden {
  opacity: 0; }
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }
.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: none;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }
.owl-carousel.owl-rtl {
  direction: rtl; }
.owl-carousel.owl-rtl .owl-item {
  float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-lazy {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

.owl-prev, .owl-next {
  position: absolute;
  z-index: 1000;
  cursor: pointer;
  color: transparent;
  outline: none;
  border: none;
}
.owl-prev {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAA8CAMAAADbh/MlAAAAwFBMVEUAAADv7+8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAaGhoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADt7e3r6+vo6Ojl5eXk5OTg4ODm5ubT09PS0tLm5ubKysrIyMjExMTBwcG/v7++vr7v7+88nHgXAAAAP3RSTlMAAAECAwQFBgcICQkKCwwNDg8QERIUFRYXGBkaHR4fICEiIyQmKistLjQ1NzlAQ4mKjI6PkZOam52ho6aoqqtl21DZAAABN0lEQVR4AYXV2VLCUAyAYciJpa2CIouiqAiKqOC+L+b938re/pNmmpncfTPtORfnb2HaGBG5r5aERpI+mGqS2Ih2Hs3yTGMjWjyZWa/IEg3Ic0Vs2Mu9IbFJv1AaR2w6LL0hsbPRjjckdj6uMSQ2H3e9IbGFMyCBAQkMSGBki8Sbimy/Wp0B+aCgIaGJCU1MaEhoYkLjCU1MaDyhCQmMaF5HYFLWfWkwonn/6K3JlIPp8qvBFJVZ/zR+6+J689fwz6PT5e0dkD/77sHsak1EU6Fi79AhmADBxAjGI5oQwcQIJkYwRDQxookRDVFkgGLTBgoMUGCAAgMUGKDf+jccaPNd2wKgm/VnXVOALlerd7bJX+bJfDHzjSMaTY4nvpVEvf5g3zeXqFOUpW83UdIsU03OAImkalv/OULgKRFEyUYAAAAASUVORK5CYII=) left center no-repeat;
}
.owl-next {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAA8CAMAAADbh/MlAAAAyVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAJCQkAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAGBgYAAAAAAAAAAAAAAAAAAAAAAAAEBAQAAAAEBAQAAAAEBAQEBATt7e3r6+vo6Ojl5eXk5OTS0tLj4+PKysrExMTZ2dm/v7++vr7AwMDPz8/KysrHx8fv7++c8kPvAAAAQnRSTlMAAQIDBAUGBwgJCgsMDg8QERIUFRcYGRoaHR4fICEiIyQnKissLC0uNDU4OTpAQkNERYmKjI6Pm5+hpqaqq66us7ZyK21iAAABNklEQVR42oXWWU+DQBSGYWBEBotIqaioXdyKWnet+8b5/z/KCYmZizc9TDJ3T2a5Od8XBGEYPrgdKCuMjJFHE4WaMXEi8rRuFBTFNhORpVVQlGRDcetZQcbmlfQgkw5r6UFmozwUHTkzmkgPMoPRVIBgZkJEQ0RDRENEQ0RDRKMjGiIaIhoiGiIaIhoiGiIaIhoi3cgycajHvA7iqMd87OXuINX8nNWF1c3v1UldpKppb86PdvS72rvL04NSfXN7v5hPtje1vzvSOGLXHIEBgSFZZb49WWW+bj2h+SdzT2BAYEBgPBl7AgMCQ0LjiTJbQGBAaEhoSDjDP0GQBe/XnqzIlLfFhUK6bHppmmOFdBk3nk33O6JkZbVblR1RMneryDNH1OxOU+uyW+8Acax2gK5LRHqX+APzAuQYrs85rgAAAABJRU5ErkJggg==) right center no-repeat;

}
