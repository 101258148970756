.masonry__column_poetry {
  position: relative;
  flex-basis: 70%;
  font: 1rem 'Alice', serif;
  color: #ffffff;
  flex-direction: row;
  @include desktop {
    flex-basis: 100%;
  }

}

.masonry__item.masonry__item_poetry-list {
  flex: 0 1 auto;
  @include desktop {
    position: absolute;
    justify-content: space-between;
    align-items: flex-start;
    top: -9999px;
    //bottom: 0;
    left: 0;
    right: 0;
    //height: 100%;
    margin: 0;
    overflow-y: scroll;
    background-color: rgba(36, 36, 36, .98);
    z-index: 100;
    &.open {
      top: 0;
      //bottom: 0;
    }
  }
}


.poetry-list {
  justify-content: space-between;
  flex: 1 1 40%;
  margin-right: 2.4rem;

}
.item_poetry-content {
  position: relative;
  flex: 1 1 60%;
  padding: rem(40px) rem(10px);


}


.poetry-list {
  display: flex;
  flex-direction: column;
  //margin-right: 10px;
  overflow: hidden;
  transition: all .4s;
  font-size: rem(18px);

  &-item{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 8px;
    @include desktop {
      width: 95%;
      overflow: visible;
    }

    &:not(:last-child) {
    margin-bottom: rem(10px);
  }

  }
  &-link{
    position: relative;
    cursor: pointer;
    padding-bottom: 8px;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 20%;
      height: 2px;
      transition: width .35s;
      background-color: #fff;

    }
    &_active,
    &:hover {
      color: #8063c0;
      //color: #6f953f;
      //font-weight: 900;
      &:after {
        //background-color: #6f953f;
        background-color: #8063c0;
        width: 100%;
      }
    }


  }

}


.poetry-content-text p {
  font-size: rem(18px);
  white-space: nowrap;
  margin: 0;
  @include phone {
    font-size: rem(10px);
  }
}

.poetry-content-item {
  display: none;
  flex-direction: column;
  align-self: center;
  justify-content: center;

  &:nth-of-type(1) {
    display: flex;
  }
}
#quatrain p:nth-child(4n){
  margin-bottom: 20px;
}

#pentameter p:nth-child(5n){
   margin-bottom: 20px;
}


.poetry-content-title {
  white-space: normal;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: rem(22px);
  @include phone {
    font-size: rem(14px);
  }
}




.poetry-copyright {
  position: absolute;
  display: flex;
  white-space: nowrap;
  margin: 10px;
  transform: rotate(-90deg);
  transform-origin: -10% 100%;
  bottom: 0;
  left: 100%;
  @include phone {
    display: none;
  }
  &__link {
    color: #8063c0;
    margin: 0  0  0 rem(10px);
  }
}

.hamburger_poetry {
  position: absolute;
  top: rem(40px);
  left: rem(40px);
  z-index: 2;

}
