* {
  box-sizing: border-box;}
html {
  font-size: 16px;

  @include desktop {
    font-size: 16px;
  }
  @include laptop {
    font-size: 15px;
  }
  @include tablet {
    font-size: 14px;
  }
  @include phone {
    font-size: 13px;  }
}

body, html {
  width: 100%;
  height: 100%;

}

body {
  font-family: 'Gotham Pro Regular', sans-serif;
  line-height: 1.42;
  -webkit-font-smoothing: antialiased;
}

.wrapper {
  display: flex;
  min-height: 100%;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  background-color: #161616;
}

.wrapper:after {
  content: "";
  display: flex;
  height: $footer-height;
  overflow: hidden;
  background-color: #343434;
}

.container {
  display: flex;
  margin: 0 auto;
  position: relative;
  width: 1300px;
  padding: 0 4%;

  @include desktop {
    width: 970px;

  }
  @include laptop {
    width: 750px;
  }
  @include tablet {
    width: 480px;

  }
  @include phone {
    width: auto;
    padding: 2% ;
  }

}


li {
  list-style: none;
}

ul {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: normal;
}
svg {
  width: 100%;
  height: 100%;
  transition: transform .45s;

}
