@font-face {
  font-family: 'Alice';
  src: url('../fonts/Alice/Alice-Regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'Proxima Nova Regular';
  src: url('../fonts/Proxima/ProximaNova-Regular.eot');
  src: url('../fonts/Proxima/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Proxima/ProximaNova-Regular.woff') format('woff'),
  url('../fonts/Proxima/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham Pro Medium';
  src: url('../fonts/Gotham/GothamPro-Medium.eot');
  src: url('../fonts/Gotham/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gotham/GothamPro-Medium.woff') format('woff'),
  url('../fonts/Gotham/GothamPro-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro Regular';
  src: url('../fonts/Gotham/GothamPro-Medium.eot');
  src: url('../fonts/Gotham/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gotham/GothamPro-Medium.woff') format('woff'),
  url('../fonts/Gotham/GothamPro-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro Light';
  src: url('../fonts/Gotham/GothamPro-Light.eot');
  src: url('../fonts/Gotham/GothamPro-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gotham/GothamPro-Light.woff') format('woff'),
  url('../fonts/Gotham/GothamPro-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}