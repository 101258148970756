
.form {
  width: 100%;
  min-width: 200px;
  color: #fff;
  font-family: 'Gotham Pro Regular', sans-serif;
  &-title {
    align-self: flex-start;
    font-size: rem(22px);
  }
  &-desc {
    margin-top: 0;
    font-size: rem(16px);
    &__mail {
      color: #fff;
      text-decoration: underline;

    }
  }


  &__column-100 {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;

  }
  &__input,
  &__textarea {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    color: #888;
    margin-bottom: 1rem;
    font-size: 1rem;
    padding: 0.5rem;
    background-color: #222222;
    border: 2px solid #888;
    outline: #F1F2F5;

    &:focus,
    &:active {
      color: #fff;
      outline: none;
      border-color: #fff;
    }
  }

  &__textarea {
    height: rem(100px);
    resize: vertical;
  }
  &__label {
    display: flex;
    align-items: center;
    justify-content: flex-start;

  }
  &__wrapped-label {
    font-size: rem(16px);
  }
  &__checkbox {
    position: absolute;
    pointer-events: none;
    opacity: 0;
    margin-top: 0;
    padding: 0;
    height: 10px;
  }
  &__checkbox + span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    background-color: transparent;
    border: 2px solid #888;
    text-align: center;
    vertical-align: top;
    margin-right: 10px;
  }
  &__checkbox + span:after{
    content: "";
    display: inline-block;
    opacity: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    transition: all 200ms;
  }
  &__checkbox:focus + span,
  &__checkbox:active + span {
    border-color: #fff;
  }
  &__checkbox:checked + span::after {
    opacity: 1;
  }
  &__checkbox + span::after {
    height: 45%;
    width: 75%;
    background-color: transparent;
    border: 0 solid #fff;
    vertical-align: bottom;

    @include transform(-45deg, 0, 0);
  }
  &__checkbox:checked + span::after {
    border-left-width: 0.2em;
    border-bottom-width: 0.2em;
  }
  &__submit {
    display: flex;
    align-self: center;
    //background-color: #0d8daa;
    border: 2px solid #fff;
    color: #fff;
    text-transform: uppercase;
    margin: rem(20px) 0;
    font-size: rem(12px);
    padding: rem(15px) rem(30px);
    //background-color: transparent;
    text-align: center;
    cursor: pointer;
    outline: none;
    letter-spacing: 1px;
    font-weight: 600;
    &:hover {
      background-color: #fff;
      border: 2px solid transparent;
      color: #000;
    }
    &:focus,
    &:active {
      border-color: #fff;
    }
  }

  &__checkbox,
  &__radio,
  &__submit {
   display: flex;
    width: auto;
  }
  .state-success {
    background-color: #00c38a;
    border-color: #00c38a;
  }
  .state-error {
    background-color: #e74750;
    border-color: #e74750;

    //box-shadow: 0 0 5px grey;
  }
  .state-initial {
    max-width: 200px;
  }
  .error {
    border-bottom-color: #e74750;
  }
}
.notification-box {
  display: none;
  padding: 1rem;
  margin: 0 1rem 1rem 1rem;
  max-height: 0;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  transition: all 600ms;

  &.show-error,
  &.show-success {
    display: block;
    border: 1px solid #e74750;
    background-color: #e74750;
    max-height: 50vh;
    //overflow: auto;

  }
  &.show-error {
    border-color: #e74750;
    background-color: #e74750;
  }
  &.show-success {
    border-color: #00c38a;
    background-color: #00c38a;
  }
}
