.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 rem(20px);
  background-color: #212121;
  margin-top: -$footer-height;
  position: relative;
  box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.75);
  color: #fff;
  .nav_footer {
    width: 33.3%;
  }
  @include tablet {
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
}

.copyright {
  white-space: nowrap;
  font-family: "Gotham Pro Light", sans-serif;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  width: 33.3%;
}

