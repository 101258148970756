@mixin box-shadow($params) {
  box-shadow: $params;
  -webkit-box-shadow: $params;
  -moz-box-shadow: $params;
}

@mixin inline-block($valign: top) {
  display: inline-block;
  vertical-align: $valign;
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin box-sizing($params) {
  box-sizing: $params;
  -webkit-box-sizing: $params;
  -moz-box-sizing: $params;
}

@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
  transition: $transition;

}
@mixin transform($deg, $transX, $transY) {
  -webkit-transform: rotate($deg) translate($transX, $transY);
  -moz-transform: rotate($deg) translate($transX, $transY);
  -ms-transform: rotate($deg) translate($transX, $transY);
  -o-transform: rotate($deg) translate($transX, $transY);
  transform: rotate($deg) translate($transX, $transY);
}
@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//responsive

@function rem($px) {
  @return $px / 16px + 0rem;
}

@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: 480px) {
    @content;
  }
}
